import React, { useState } from 'react'
import classnames from 'classnames'
import TROUSERS_RANGE from '@consts/trousers.json'
import ResponsiveHOC from '@components/ui/ResponsiveHOC'
import { jsonToArray } from '@helpers/contentful'
import { CardItem } from './PBGrid/index'
import SectionHeader from '@components/ui/SectionHeader'
import { mergeProductType } from '@helpers/products'
import PBGrid from './PBGrid'
import styles from './styles.module.scss'

type Categories = {
  items: Partial<CardItem>[]
}

export type Category =
  | 'homepage'
  | 'page'
  | 'other'
  | 'custom'
  | (string & NonNullable<unknown>)

export type ProductsBreakdownProps = {
  categories: Categories
  title?: string
  byline?: string
  category: Category
  toggle?: boolean
  productType?: string
  firstTimeUser?: boolean
  isAbTest?: boolean
  visible: boolean | null
}

const ProductsBreakdown = ({
  categories = { items: TROUSERS_RANGE.all_styles },
  title,
  byline,
  category,
  toggle = false,
  productType = '',
  firstTimeUser = false,
  isAbTest = false,
  visible = true
}: ProductsBreakdownProps) => {
  const cards = jsonToArray(categories?.items || categories).filter(item => {
    return item.title !== mergeProductType(productType)
  }) as CardItem[]
  const cardHasBadge = cards.filter(item => !!item.badge).length > 0
  const derivedCards = isAbTest
    ? cards.map(card => ({ ...card, description: '' }))
    : cards

  const [isVisible, setIsVisible] = useState(!toggle)

  const handleClick = () => {
    setIsVisible(!isVisible)
  }

  const gridProps = {
    firstTimeUser,
    cards: derivedCards,
    category,
    withBadge: cardHasBadge
  }

  if (!visible) {
    return null
  }

  if (!title) {
    return (
      <section className={styles.productsBreakdown}>
        <PBGrid {...gridProps} visible />
      </section>
    )
  }

  const commonProps = {
    byline,
    title
  }

  return (
    <section className={styles.productsBreakdown}>
      {toggle ? (
        <>
          <ResponsiveHOC maxWidth={699}>
            <button
              type="button"
              className={classnames(
                `${styles.productsBreakdown__button} ${
                  isVisible ? styles.productsBreakdown__button_active : ''
                }`
              )}
              onClick={handleClick}
            >
              {title}
              <span className={styles.productsBreakdown__button__icon} />
            </button>
          </ResponsiveHOC>
          <ResponsiveHOC minWidth={700}>
            <SectionHeader {...commonProps} />
          </ResponsiveHOC>
        </>
      ) : (
        <SectionHeader {...commonProps} />
      )}
      <ResponsiveHOC maxWidth={699}>
        <PBGrid {...gridProps} visible={isVisible} />
      </ResponsiveHOC>
      <ResponsiveHOC minWidth={700}>
        <PBGrid {...gridProps} visible />
      </ResponsiveHOC>
    </section>
  )
}

export default ProductsBreakdown
