import React, { useState, useEffect } from 'react'
import { isBrowser } from '@helpers/env'

type ResponsiveHOCProps = {
  minWidth?: number
  maxWidth?: number
}

const ResponsiveHOC = ({
  children,
  minWidth = 0,
  maxWidth = 10000
}: React.PropsWithChildren<ResponsiveHOCProps>) => {
  const getSize = () => {
    return {
      width: isBrowser() ? window.innerWidth : 1000
    }
  }
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isBrowser()) return
    const handleResize = () => {
      setWindowSize(getSize())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  if (windowSize.width < maxWidth && windowSize.width >= minWidth) {
    return <>{children}</>
  }

  return null
}

export default ResponsiveHOC
